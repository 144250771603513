<template>
  <div class="vps-order page">
    <h1 class="page-title">
      {{ $t('title') }}
    </h1>
    <transition name="fade" mode="out-in">
      <div v-if="loading && !list.length" class="vps-order__loading">
        <base-loader />
      </div>
      <div v-else-if="list.length" class="vps-order__content">
        <page-block :title="$t('choise')" size="bigger">
          <pricelist-block :list="list">
            <template v-slot:item="{ item }">
              <pricelist-card
                :title="item.name"
                :list="getSpecs(item)"
                :id="item.id"
                :price="item.cost"
                :active="current && current.id === item.id"
                :discount="
                  +item.price.period[0].discount_amount > 0 ? item.price.period[0].full_cost : null
                "
                @click="clickedDetected(item, period)"
              />
              <!--                @click="setCurrent(item, period)"-->
            </template>
          </pricelist-block>
        </page-block>
        <transition name="fade" mode="out-in">
          <!--          <base-loader v-if="!configurator.length && $route.params.id" />-->
          <!--          <base-loader v-if="!configurator.length && $route.query.id && !current" />-->
          <!--          <div v-if="handlerStartLoadingModal && $route.query.id" class="vps-order__loading">-->
          <div v-if="handlerStartLoadingModal" class="vps-order__loading">
            <base-loader v-if="$route.query.id" />
          </div>
          <!--          <base-loader v-if="handlerStartLoadingModal && $route.query.id" />-->
          <page-block v-else :title="$t('additional')" size="bigger">
            <!--          <page-block v-if="configurator.length" :title="$t('additional')" size="bigger">-->
            <div id="el" class="l-flex-gutter-20">
              <div class="l-col-12_ml-8">
                <main-card class="vps-order__card">
                  <order-config
                    :key="current.id"
                    :configurator="current"
                    class="vps-order__config"
                    :changable="true"
                    :period="period"
                    :label-length-style="labelLengthStyle"
                    @init="updateConfig"
                    @change="updateConfig"
                    @destroy="resetConfig"
                  />
                  <transition name="fade" mode="out-in">
                    <page-block v-if="costDetails" :title="$t('summary')" size="bigger">
                      <order-summary :details="costDetails" />
                    </page-block>
                  </transition>
                  <template #footer>
                    <div class="vps-order__footer">
                      <div class="vps-order__price">
                        <span class="vps-order__sum bigger-heading">
                          {{ $n(cost, 'currency') }}
                        </span>
                        <span class="vps-order__price-text">
                          {{ $t('payfor') }}
                        </span>
                        <base-select
                          :value="periodValue"
                          :options="priceByPeriod"
                          :searchable="false"
                          :allow-empty="false"
                          label="label"
                          :show-labels="false"
                          class="vps-order__period"
                          @input="onPeriodChange"
                        />
                      </div>
                      <div id="period" class="vps-order__actions">
                        <base-button
                          :loading="isSendingToPay"
                          :disabled="isCalculating || isSendingToBasket"
                          class="vps-order__btn"
                          @click="sendToPay()"
                        >
                          {{ `${$t('pay')}  ${$n(cost, 'currency')}` }}
                        </base-button>
                        <base-button
                          :loading="isSendingToBasket"
                          :disabled="isCalculating || isSendingToPay"
                          theme="outlined"
                          class="vps-order__btn"
                          @click="sendToBasket()"
                        >
                          {{ $t('order') }}
                        </base-button>
                      </div>
                    </div>
                  </template>
                </main-card>
              </div>
            </div>
          </page-block>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
import OrderConfig from '@/components/Configurator/OrderConfig';
import OrderSummary from '@/components/Configurator/OrderSummary';
import MainCard from '@/components/MainCard/MainCard';
import BaseSelect from '@/components/Select/BaseSelect';
import { debounce, isEqual } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizard from '@/mixins/billmgr/wizard';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import VueScrollTo from 'vue-scrollto';
import Vue from 'vue';
Vue.use(VueScrollTo);
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import getMaxPixelsOfStrings from '@/utils/getMaxPexelsOfStrings';
// import getMaxPixelsOfStrings from '@/utils/getMaxPexelsOfStrings';
export default {
  name: 'VpsOrder',
  components: { PricelistBlock, PricelistCard, OrderConfig, OrderSummary, MainCard, BaseSelect },
  mixins: [showErrorModal, wizard, handleRedirect, updateBalance],
  data() {
    return {
      configData: null,
      isLoadingData: false,
      period: 1,
      id: '',
      handlerStartLoadingModal: true,
      labelLengthStyle: null,
      cost: 0,
      costDetails: null,
      isCalculating: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      startFunc: 'payment.add',
      startParams: {},
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    // isLoadingCurrentTariff() {
    //   return (
    //     !this.configurator.length &&
    //     this.$route.query.id &&
    //     this.current &&
    //     this.current.info &&
    //     this.current.info.model
    //   );
    // },
    isDataLoading() {
      return this.current?.configurator?.length && this.current?.info?.model;
    },
    list() {
      return this.$store.state.moduleVPS.moduleVpsOrder.list;
    },
    loading() {
      return this.$store.state.moduleVPS.moduleVpsOrder.loading;
    },
    current() {
      return this.$store.getters['moduleVPS/moduleVpsOrder/current'];
    },
    configurator() {
      if (!this.current) return [];
      return this.current.configurator || [];
    },
    configTextFields() {
      return this.configurator.filter(addon => addon.type === 'text');
    },
    configLabels() {
      return this.configurator.reduce((acc, item) => {
        acc[item.name] = item.label;
        return acc;
      }, {});
    },
    priceByPeriod() {
      return this.current.priceByPeriod.map(i => ({
        label: this.$tc(`period.${BillMgrPeriods[i.period]}_c`, i.period),
        value: i.period,
      }));
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === this.period);
    },
  },
  watch: {
    current: function (event) {
      // console.log(('current', event);
      // this.period = 1;
    },
    isDataLoading(event) {
      // console.log(('---isDataLoading---', event);
    },
    period(event) {
      // console.log((event);
      if (event === NaN) this.$route.query.period = 1;
      else this.$route.query.period = event;
    },
    configData: {
      handler: function (oldVal, newVal) {
        console.log(oldVal?.period, newVal?.period);
        if (newVal && oldVal && this.isLoadingData) {
          const obj = this.current?.info?.customfields;
          const labels = [];
          Object.keys(obj).map(item => {
            if (obj[item].type === 'slider') {
              labels.push(obj[item].label);
            }
          });
          this.labelLengthStyle = getMaxPixelsOfStrings({
            strings: labels,
            styles: {
              'font-style': 'normal',
              'font-weight': 500,
              'font-size': '14px',
              'line-height': '20px',
            },
          });
          this.$scrollTo('#el', 1500, { offset: -200 });
          this.isLoadingData = false;
        }
        // this.period = +val;
      },
      immediate: true,
      deep: true,
    },
    '$route.query.id': {
      handler: function (val) {
        console.log('$route.query.id', val);
        // // console.log((val, this.loading, this.list, this.id);
        if (val && this.configurator.length === 0 && this.list.length > 0) {
          this.id = +val;
          const tariff = this.list.find(i => i.id === this.id);
          console.log('setCurrent', tariff, this.period);
          this.setCurrent(tariff, this.period || 1);
        }
      },
      immediate: true,
    },
    '$route.query.period': {
      handler: function (val) {
        console.log('$route.query.period', val);
        if (val && this.configurator.length === 0 && this.list.length > 0) {
          this.period = +val;
          const tariff = this.list.find(i => i.id === this.id);
          console.log('setCurrent', tariff, this.period);
          this.setCurrent(tariff, this.period || 1);
        }

        // if (val) this.period = +val;
        // else this.period = 1;
      },
      immediate: true,
    },
  },
  mounted() {
    // if (isNaN(this.$route.query?.period)) this.$route.query.period = 1;
    // console.log((this.$route.query?.id && this.$route.query?.period, 'mounted');
    if (!this.$route.query?.id && !this.$route.query?.period) {
      console.log('mount', this.$route.query?.id && this.$route.query?.period);
      this.$router
        .push({
          name: 'vpsOrder',
          // query: { id: item.id, period: this.period },
          replace: true,
        })
        .catch(() => {});
      // else {
    } else if (
      this.$route.query?.id &&
      this.$route.query?.period &&
      ![1, 3, 6, 12, 24].includes(+this.$route.query?.period)
    ) {
      console.log('mount', this.$route.query?.id && this.$route.query?.period);
      this.id = +this.$route.query.id;

      this.period = [1, 3, 6, 12, 24].includes(+this.$route.query?.period)
        ? +this.$route.query.period
        : 1;
      this.$router
        .push({
          name: 'vpsOrder',
          query: {
            id: this.$route.query.id,
            period: this.period,
          },
          replace: true,
        })
        .catch(() => {});
    } else {
      console.log('mount', this.$route.query?.id && this.$route.query?.period);
      this.$router
        .push({
          name: 'vpsOrder',
          query: {
            id: this.$route.query.id,
            period: +this.$route.query?.period,
          },
          replace: true,
        })
        .catch(() => {});
    }
    // }
    this.fetchPricelist()
      .then(data => {
        // console.log((data);
        return data;
      })
      .then(data => {
        // console.log((this.id, this.$route.query.id);
        const tariff = +this.$route.query?.id
          ? data.find(i => i.id === +this.$route.query.id)
          : null;
        // // console.log((tariff);
        console.log('mount setCurrent', +this.$route.query.id, this.period);
        if (+this.$route.query?.id && this.period) this.setCurrent(tariff, this.period || 1);
      });
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleVPS/moduleVpsOrder/reset');
    next();
  },
  methods: {
    // api and logic methods
    fetchPricelist() {
      return this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchPricelist');
    },
    fetchCalc(data) {
      return this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchCalc', data);
    },
    async clickedDetected(item, period) {
      console.log('clickedDetected');
      await this.setCurrent(item, period);
    },
    async setCurrent(item, period) {
      console.log('setCurrentItem', item, item?.info, period);
      this.handlerStartLoadingModal = true;
      // this.updateConfig(item);
      this.id = item.id;
      // if (!this.$route.query?.id && !this.$route.query?.period)
      this.$router
        .push({
          name: 'vpsOrder',
          query: { id: item.id, period: period },
          replace: true,
        })
        .catch(() => {});
      await this.$store.dispatch('moduleVPS/moduleVpsOrder/setCurrent', item.id);
      console.log('fetchParams', item, period);
      await this.fetchParams(item, period).then(async data => {
        console.log('fetchParams ___ data', data?.model, this.current?.info?.model);
        // console.log((this.current?.info?.model);
        this.calcSum(this.current?.info?.model);
      });
    },
    fetchParams(item, period) {
      console.log('fetchParams', item, period);
      this.isLoadingData = true;
      const params = {};
      if (item) params.id = item.id;
      if (period) params.period = period;
      return this.$store.dispatch('moduleVPS/moduleVpsOrder/fetchParams', params);
    },
    calcSum: debounce(function (payload = this.configData) {
      console.log('calcSum', 'isCalculating', this.isCalculating, payload, this.configData?.period);
      const params = {
        pricelist: this.current?.id,
      };
      let isChanges = !isEqual(this.configData, payload);
      console.log('isChanges', isChanges);
      // if ((payload && isChanges) || !this.isCalculating) Object.assign(params, payload);
      if (payload && !this.isCalculating) Object.assign(params, payload);
      params.period = this.period;
      if (!this.isCalculating) {
        this.isCalculating = true;
        this.fetchCalc(params)
          .then(data => {
            console.log(data?.model?.cost, data?.model?.cost_details);
            const { cost, cost_details } = data.model;
            this.cost = parseFloat(cost);
            this.costDetails = cost_details;
            this.costDetails.other = this.configTextFields.map(addon => ({
              label: addon.label,
              value: this.configData?.[addon.name],
            }));
          })
          .catch(e => this.showError(e))
          .finally(() =>
            setTimeout(() => {
              this.isCalculating = false;
              if (this.configurator.length !== 0 && this.current?.info) {
                this.handlerStartLoadingModal = false;
                // } else {
                //   console.log('calcSum fetchParams', this.current, this.period);
                //   this.fetchParams(this.current, this.period).then(() => {
                //     this.calcSum(this.current?.info?.model);
                //   });
              }
            }, 100)
          );
      }
    }, 100),
    getFormDataParams(payload = {}) {
      const formData = this.configData ? this.configData : this.current.info.model;
      if (
        this.configData &&
        this.configData.autoprolong &&
        this.configData.autoprolong !== 'null'
      ) {
        formData.autoprolong = this.period;
      }
      const params = {
        pricelist: this.current.id,
        ...formData,
        ...payload,
        period: this.period,
      };
      return params;
    },
    sendOrder(payload = {}) {
      const params = this.getFormDataParams(payload);
      return this.$store
        .dispatch('moduleVPS/moduleVpsOrder/sendOrder', params)
        .catch(e => this.showError(e));
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      this.sendOrder()
        .then(data => {
          const cost = parseFloat(data.itemdesc[0].total_cost);
          this.resetCurrent();
          const text = this.$t('success.basket', { num: this.$n(cost, 'currency') });
          this.$store.dispatch('moduleBasket/fetchBasket');
          this.showSuccessModal(text);
        })
        .finally(() => setTimeout(() => (this.isSendingToBasket = false), 100));
    },
    sendToPay() {
      const payload = {
        not_for_order: 'on',
        skipbasket: '',
        newface: 'on',
        newbasket: 'on',
        clicked_button: 'basket',
        endoflife: 'off',
      };
      const params = this.getFormDataParams(payload);
      this.showResFunc.success = false;
      this.isSendingToPay = true;
      this.sendOrder(params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizard()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
                  this.showSuccessModal(text);
                }
                this.resetCurrent();
                this.updateBalance();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket');
                  });
                }
              });
          }
        })
        .finally(() => setTimeout(() => (this.isSendingToPay = false), 100));
    },
    // data methods
    updateConfig(data) {
      console.log('updateConfig', data);
      this.isCalculating = false;
      // console.log(Object.keys(data));
      if (Object.keys(data).length) this.configData = { ...data };
      if (Object.keys(this.configData)) this.calcSum();
    },
    resetConfig() {
      this.configData = null;
      this.costDetails = null;
    },
    resetCurrent() {
      console.log('reset');
      this.resetConfig();
      this.period = 1;
      this.$store.commit('moduleVPS/moduleVpsOrder/setCurrent', null);
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      this.$router
        .push({
          name: 'vpsOrder',
          query: { id: this.id, period: obj.value },
          replace: true,
        })
        .catch(() => {});
      this.$scrollTo('#period', 1500);
      this.period = obj.value;
      // this.calcSum();
      const tariff = this.list.find(i => i.id === this.id);
      console.log('setCurrent', tariff, this.period);
      this.setCurrent(tariff, this.period);
    },
    // util methods
    getSpecs(item) {
      const list = [];
      for (let prop of Object.keys(item.specs)) {
        list.push(this.$t(`specs.${prop}`, { n: item.specs[prop] }));
      }
      return list;
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Создание VPS сервера",
    "choise": "Выберите тариф",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Оплатить",
    "order": "Сформировать заказ",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Сервер успешно создан, {num} списано с лицевого счета",
      "redirect": "Сервер успешно создан, продолжите оплату и дождитесь обновления данных"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
</style>
