/**
 lengts for string
 */
export default function getMaxPixelsOfStrings({ strings, styles = {} }) {
  const spans = strings.map(str => {
    const span = document.createElement('span');
    span.append(str);
    Object.assign(span.style, {
      position: 'absolute',
      ...styles,
    });

    return span;
  });

  document.querySelector('html').prepend(...spans);
  const maxPixels = Math.max(...spans.map(span => span.getBoundingClientRect().width));

  spans.forEach(span => span.remove());

  return maxPixels;
}
// getMaxPixelsOfStrings({
//   strings: ['One', 'Two', 'Three', 'Four', 'Five'],
//   styles: {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
//   },
// })
