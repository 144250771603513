// import { OPState } from '@/models/OP/OPState';
// import { OPTask } from '@/models/OP/OPTask';
// import { OPBalancerStatusOperating, OPBalancerStatusProvisioning } from '@/models/OP/OPBalancer';

export class OPObject extends Object {
  /*************
   *
   * i - чтобы можно было отличить и добавить валидацию на сущность, если это понадобится в дальнейшем
   * _source - отвечает за это
   *
   * *****************/
  constructor(args, i) {
    // console.log(args);
    super(args);
    if (args?.Prefix) {
      this.prefix = args?.Prefix;
      this.uniqueName = args?.Prefix.slice(0, -1).split('/').at(-1);
      this.name = args?.Prefix;
      this.policy = args?.Policy;
      this.key = args?.Prefix;
      this.isPrefix = false;
      this.isFolder = true;
      this.isShared = false;
      this.isDeny = false;
    } else {
      this.size = args?.Size;
      this.isShared = false;
      this.actualSize = Math.ceil(args?.Size / 4096) * 4096;
      this.isFolder = !this.actualSize;
      this.source = 'object';
      this.name = args?.Key;
      // this.name = this.isFolder ? args?.Key.slice(0, -1) : args?.Key;
      this.key = args?.Key;
      this.path = this.name ? this.getPath(this.name) : '/';
      this.class = args?.StorageClass;
      this.metadata = args?.Metadata;
      this.date = args?.LastModified;
      this.uniqueName = this.name?.includes('/') ? this.name?.split('/').at(-1) : this.name;
      this.owner = args?.Owner;
      this.type = args?.ContentType;
      this.grants = args?.Grants;
      this.eTag = args?.ETag;
    }
    // this._init(args, i);
    // this._headers = [];
  }
  /*
   * из разных модулей опенстака прилетает разный формат даты
   *     .000000 -> не отображает нормально часовой пояс
   *     Z -> отображает нормально
   * поэтому приводим к единому виду
   *
   * */
  // parseDate(args) {
  //   let date = args ? args.split('.') : null;
  //   let parsedDate;
  //   if (date) {
  //     parsedDate = date && date.length > 1 ? date[0] + 'Z' : date[0];
  //   } else parsedDate = null;
  //   return parsedDate ? new Date(parsedDate) : null;
  // }
  getPath(name) {
    const isChildren = name.includes('/');
    // const key = name.replaceAll('/', '-');
    const lastIndex = name.lastIndexOf('/');
    return isChildren ? '/' + name.slice(0, lastIndex) : '/';
  }

  _init(args) {
    Object.keys(args).forEach(k => {
      // if (args[k] === args.LastModified) {
      //   this.LastModified = this.parseDate(args[k]);
      // } else
      this[k] = args[k];
    });
  }

  // set headers(headers) {
  //   this._headers = headers;
  //   Object.keys(headers).forEach(k => {
  //     if (headers[k] === headers.date) {
  //       this[k] = this.parseDate(headers[k]);
  //     } else if (headers[k] === headers['last-modified']) {
  //       this.lastModified = this.parseDate(headers[k]);
  //     } else if (k === 'x-container-bytes-used-actual') {
  //       this.bytes = +headers[k];
  //     } else if (k.includes('x-')) {
  //       this[k] = headers[k];
  //     }
  //   });
  // }
  // get headers() {
  //   return this._headers;
  // }

  updateResources(list) {
    list.forEach(i => {
      // console.log(i);
      if (this[i.intname]) this[i.intname].value = i.value;
    });
  }

  update(args) {
    this._init(args);
  }
}
