export default class SliderComponent {
  constructor({ value, min, max, step, measure, limit, readonly, ext, data, required }) {
    this.value = parseInt(value) || 0;
    if (step === '1024' && measure === 'МиБ') {
      this.additionalStep = true;
      this.value = this.value / 1024;
      if (data) this.sliderData = data;
      if (!data) {
        this.min = parseInt(min / step) || 0;
        this.max = parseInt(max / step) || 0;
        this.step = 1;
        this.limit = parseInt(limit / step) || min;
      }
      if (measure) this.measure = 'ГиБ';
    } else {
      if (data) this.sliderData = data;
      if (!data) {
        this.min = parseInt(min) || 0;
        this.max = parseInt(max) || 0;
        this.step = parseInt(step) || 1;
        this.limit = parseInt(limit) || min;
      }
      if (measure) this.measure = measure;
    }
    this.manualprocessing = ext && ext.manualprocessing === 'on';
    this.restrictclientchange = ext && ext.restrictclientchange ? ext.restrictclientchange : 'off';
    this.required = required && required === 'yes';
    this.disabled = false;
  }
}
