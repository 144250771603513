<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('disk.label') }}</label>
      <base-input
        v-model="formData.diskName"
        :required="true"
        :placeholder="$t('disk.placeholder')"
        class="form__field--input section-header"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('label.image') }}</label>
      <base-input
        :value="name"
        :required="true"
        class="form__field--input section-header"
        :readonly="true"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('label.type') }}</label>
      <base-select
        v-model="type"
        select-label=""
        :required="true"
        :hide-selected="true"
        :searchable="false"
        placeholder="Выберите тип диска"
        :options="typeOfDisk"
        track-by="name"
        :custom-label="nameType"
        size="medium"
        :allow-empty="false"
        class="new-disk__services section-header"
        @input="onChange"
      >
      </base-select>
    </div>
    <div class="l-col resize-row section-header">
      <div class="resize-row-text">
        <label class="standart-text">{{ $t('disk.size') }}</label>
      </div>
      <div class="resize-row-text">
        <InputNumber
          v-model="formData.size"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="minSizeDisk"
          :max="quota"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', 'max-width': '4rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange('size', $event)"
        />
      </div>
    </div>
    <div class="l-col">
      <div v-if="formData.size < size || formData.size > quota" :style="{ 'text-align': 'center' }">
        <label class="typo__label section-header standart-text error-color">{{
          size > formData.size ? $t('quotaDown') : $t('quotaUp')
        }}</label>
      </div>
      <div v-else>
        <p v-html="$t('cost', { msg: cost.toFixed(2) })" class="standart-text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import InputNumber from 'primevue/inputnumber';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'NewDiskFromImage',
  components: { InputNumber, BaseInput, BaseSelect },
  mixins: [setFocus],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
      require: true,
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: '',
      type: this.defaultTypeOfDisk,
      image: [],
      formData: {
        diskName: '',
        from: 'image',
        size: this.size,
        name: '',
        id: '',
        type: 'SSD',
        func: '',
      },
    };
  },
  computed: {
    name() {
      return `${this.instance.volume.imageRef} (${Math.ceil(
        this.instance.volume.size / Math.pow(1024, 3)
      )} ГБ)`;
    },
    price() {
      const type = this.formData.name;
      return type && this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === `volume_${type}`)[dev]
        : 0;
    },
    cost() {
      return this.formData.size && this.price ? this.formData.size * this.price : 0;
    },
    nameDisk() {
      return `${
        this.$store.state.moduleStack.images.find(x => x.id === this.instance.volume.imageRef).name
      }-disk`;
    },
    typeOfDisk() {
      return this.$store.state.moduleStack.typesOfDisks;
    },
    minSizeDisk() {
      return this.formData.size > this.size ? this.formData.size : this.size;
    },
    defaultTypeOfDisk() {
      return this.typeOfDisk.filter(x => x.name === 'SSD');
    },
  },
  mounted() {
    this.$emit('notready');
    if (!this.formData.diskName) this.formData.diskName = this.nameDisk;
    if (this.formData.diskName && this.formData.size && this.formData.name) {
      return this.$emit('change', {
        name: this.formData.diskName,
        size: this.formData.size,
        imageRef: this.instance.volume.imageRef,
        volume_type: this.formData.name,
      });
    }
  },
  methods: {
    nameImage({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameType({ name }) {
      return `${name}`;
    },
    onChange(event) {
      Object.assign(this.formData, event);
      if (this.formData.diskName && this.formData.size && this.formData.name) {
        return this.$emit('change', {
          name: this.formData.diskName,
          size: this.formData.size,
          imageRef: this.instance.volume.imageRef,
          volume_type: this.formData.name,
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "type": "Тип",
      "description": "Описание",
      "image" : "Образ",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снапшот как источник"
    },
    "quotaUp": "Значение превышает максимально разрешенное",
    "quotaDown": "Уменьшать диск нельзя",
    "disk": {
      "label": "Имя диска",
      "placeholder": "Имя вашего диска",
      "description" : "Текстовое описание",
      "snap" : "Использовать снапшот как источник",
      "hint": "Указажите размер создаваемого диска.",


      "size": "Размер диска"
    },
    "cost": "Расходы увеличатся на <b>%{msg}</b> ₽ в час",
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.section-header {
  margin-top: 0.5rem;
}
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    margin: 1.5rem 0 1.5rem 0;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
