import container from './container.vue';
export const RouteVPS = {
  name: 'VPS',
  path: '/vds',
  component: container,
  redirect: { name: 'vpsMain' },
  meta: {
    isShow: true,
    isLink: true,
    name: 'VPS серверы',
    navigation: {
      service: {
        icon: 'vds',
        key: 'vps-servers',
        order: 2,
      },
    },
    access: ['mainmenuservice.vds'],
  },
  children: [
    {
      path: '',
      name: 'vpsMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'VPS серверы',
      },
      component: () => import(/* webpackChunkName: "vps-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order',
      name: 'vpsOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Создание VPS сервера',
      },
      component: () => import(/* webpackChunkName: "vps-order" */ './pages/Order/VpsOrder.vue'),
      // children: [
      //   {
      //     path: '/vds/order',
      //     redirect: { name: 'vpsOrder' },
      //   },
      //   {
      //     path: '/vds/order/:id/:period',
      //     name: 'vpsOrderDetails',
      //     meta: { name: 'заказ VPS' },
      //     props: true,
      //     component: () =>
      //       import(/* webpackChunkName: "vps-order-details" */ './pages/Order/VpsOrderDetails.vue'),
      //   },
      //   {
      //     path: '/vds/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
      //     redirect: { name: 'vpsOrderDetails' },
      //   },
      // ],
    },
    {
      path: ':id',
      name: 'vpsDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "vps-info" */ './pages/Information/container.vue'),
      redirect: { name: 'vpsView' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "vps-info-view" */ './pages/Information/views/View.vue'),
          name: 'vpsView',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vps-info-change" */ './pages/Information/views/Config.vue'
            ),
          name: 'vpsConfig',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vps-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'vpsBackup',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vps-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'vpsHistory',
        },
        {
          path: 'access',
          component: () =>
            import(/* webpackChunkName: "vps-info-root" */ './pages/Information/views/Access.vue'),
          name: 'vpsAccess',
        },
        {
          path: 'ip',
          component: () =>
            import(/* webpackChunkName: "vps-info-ip" */ './pages/Information/views/IpTable.vue'),
          name: 'vpsIp',
          meta: {
            isShow: true,
            isLink: false,
            name: 'IP-адреса',
          },
        },
      ],
    },
  ],
};
