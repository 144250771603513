import DeleteObject from '@/layouts/Stack/components/DeleteObject';
import Vue from 'vue';
import modals from '@/mixins/modals';
export default {
  mixins: [modals],
  data() {
    return {
      count: '',
      isSuccessDeleted: false,
      arraysForDelete: [],
      params: {},
    };
  },
  methods: {
    deleteObjects(storage, item) {
      const that = this;
      return new Promise((resolve, reject) => {
        this.$modals.open({
          name: 'DeleteObject',
          size: 'medium',
          component: DeleteObject,
          closeOnBackdrop: false,
          props: { storage: storage, item: item },
          on: {
            change: data => {
              console.log(data);
              this.arraysForDelete = data.storage.storage.objects
                .filter(x => x.key.startsWith(data.storage.name))
                .map(x => x.key);
              console.log(this.arraysForDelete);
              this.params = Object.assign({}, data.storage);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm'), color: 'error' },
              on: {
                click: async () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  console.log(storage.storage.name);

                  const promises = [];
                  this.arraysForDelete.forEach(item => {
                    promises.push(
                      this.$store.dispatch('moduleStack/deleteS3Object', {
                        bucket: storage.storage.name,
                        name: item,
                      })
                    );
                  });
                  await Promise.all(promises).finally(() => {
                    that.$modals.close();
                    resolve('ok');
                    //  this.fetchStorage(storage.storage.name);
                    // this.$store.dispatch('moduleBasket/fetchBasket');
                  });

                  // await this.$store
                  //   .dispatch('moduleStack/deleteS3Object', this.params)
                  //   .then(data => {
                  //     if (data.$metadata.httpStatusCode === 204) {
                  //       this.isSuccessDeleted = true;
                  //     }
                  //     that.$modals.close();
                  //     resolve('ok');
                  //   })
                  //   .catch(e => {
                  //     that.$modals.close();
                  //     reject(e);
                  //   });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
  },
};
