import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';
import MyRsxExtApi from '@/services/api/MyRsxExtApi';
import VmRougeApi from '@/services/api/VmRougeApi';
const actions = {
  fetchPricelist({ commit, dispatch }, payload = {}) {
    commit('setLoading', true);
    const params = {
      onlyavailable: 'on',
      itemtype: 'vds',
      tariffpage: 'basic',
      ...payload,
    };
    return BillMgrApi.get('api2/billmgr/pricelist.export', { params })
      .then(data => {
        if (data.pricelist) commit('addToList', data.pricelist);
        dispatch('fetchWizard');
        return data?.pricelist;
      })
      .finally(() => commit('setLoading', false));
  },
  fetchWizard({ commit }, payload = {}) {
    const params = {
      func: 'vds.order',
      ...payload,
    };
    return BillMgrApi.get('billmgr', { params }).then(data => {
      if (data.wizard) commit('setWizard', data.wizard);
      if (data.model) commit('setModel', data.model);
    });
  },
  fetchParams({ commit, state, dispatch }, { id = null, period = 1 }) {
    const innerId = id || state.current;
    const params = {
      ...state.model,
      period,
      sok: 'ok',
      pricelist: innerId,
      newface: 'on',
      func: state.wizard.params || 'vds.order.pricelist',
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData)
      .then(data => {
        commit('setItemProp', {
          id: id,
          prop: 'info',
          value: data,
        });
        console.log('-----------1----------------', data);
        return data;
      })
      .then(data => {
        dispatch('fetchAddonsExt', id);
        console.log('-----------2----------------', data);
        return data;
      })
      .catch(e => console.error(e));
  },
  fetchAddonsExt({ commit }, id) {
    return MyRsxExtApi.get(`addons/${id}`)
      .then(data => {
        commit('setItemProp', { prop: 'addonsExt', value: data });
        return 'ok';
      })
      .catch(e => console.error(e));
  },
  setAutoProlongInfo({ commit }, value) {
    commit('setAutoProlongInfo', value);
  },
  fetchCalc(_ctx, model) {
    const params = {
      func: 'pricelist.calculate',
      out: 'bjson',
      auth: localStorage.getItem('token'),
      ...model,
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData);
  },
  sendOrder({ state }, model) {
    const params = {
      func: state.wizard.order,
      out: 'bjson',
      auth: localStorage.getItem('token'),
      ...model,
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('billmgr', formData);
  },
  setCurrent({ commit }, id) {
    commit('setCurrent', id);
  },
  reset({ commit }) {
    commit('resetState');
    commit('SET_BACKUPS_INFO', []);
    commit('setVpsList', []);
  },

  fetchBackupsInfo(_ctx, { serverId }) {
    const params = {
      instanceuuid: serverId,
      state: 'finished',
    };
    const url = '/backups';
    return new Promise((resolve, reject) => {
      VmRougeApi.get(url, serverId ? { params } : null)
        .then(({ data }) => resolve(data))
        .catch(e => reject(e));
    });
  },
  setBackupsInfo({ commit, dispatch }, serverId) {
    dispatch('fetchBackupsInfo', { serverId }).then(data => {
      commit('SET_BACKUPS_INFO', data.Items);
      commit('setBackupCount', data.TotalCount);
    });
  },
  updateBackups({ commit }, listBackups) {
    commit('updateBackups', listBackups);
  },
  setBackupAction({ commit }, params = {}) {
    let method = params.action === 'restore' ? '/instances' : '/backups';
    let url = !params.serverid ? method : `${method}/${params.serverid}`;
    if (params.action && params.serverid) url += `/${params.action}`;
    return new Promise((resolve, reject) => {
      VmRougeApi[params.method](url, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};

export default actions;
